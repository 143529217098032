$phoneWidth: 640px;

@mixin phone {
  @media screen and (max-width: $phoneWidth) {
    @content; } }

.systemBGColor {
  background-color: #84d6bf; }
.ril__navButtonPrev {
  display: none; }
.ril__navButtonNext {
  display: none; }
.systemFontColor {
  &--dark {
    color: rgb(16, 141, 97); } }
.view-list {
  &-case {
    height: calc(100vh - 390px); }
  &-chat {
    height: calc(100vh - 200px); }
  &-contact {
    height: calc(100vh - 200px); }
  &-notify {
    height: calc(100vh - 155px); } }

.pageRightBlock {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  transition: all 0.4s;
  @include phone {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;
    overflow: auto; }
  &--show {
    @include phone {
      visibility: visible;
      transform: translateX(0); } } }
.avatar-xxl {
  height: 12rem;
  width: 12rem;
  @include phone {
    height: 6rem;
    width: 6rem; } }
.viewPage {
  overflow: hidden;
  &__wrapper {
    @include phone {
      margin-top: 0;
      padding: 0 3em; }
    &--noMargin {
      margin: 0; }
    &--empty {
      margin: 0;
      height: calc(100vh - 80px);
      line-height: calc(100vh - 80px);
      text-align: center;
      font-size: 3.5em;
      @include phone {
        font-size: 1.5em; } } }
  &__label {
    color: rgb(132,214,191); }
  &__item {
    border-bottom: 1px solid #f0eff5!important;
    padding: 1em 0; }
  &__txt {
    display: block;
    &--card {
      box-shadow: 0 6px 18px 0 rgba(0,35,90,.08), 0 0 2px 0 rgba(0,35,90,.06);
      background-color: #fff;
      margin-bottom: 0.5em;
      margin-top: 0.5em; } }
  &__img {
    text-align: center;
    margin-bottom: 1.5em;
    border-bottom: 1px solid #f0eff5!important;
    padding: 1.5em;
    @include phone {
      padding: 1em;
      margin-bottom: 1em; } } }
.notify {
  &__wrapper {
    background-color: #fff; } }
.text-error {
  color: #f25178; }
.p-4-lr {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }
.user-profile-sidebar {
  position: fixed;
  right: 0; }
.popCount {
  color: #fff;
  position: absolute;
  background-color: #f25178;
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  font-weight: normal;
  margin-top: 2px;
  margin-left: -2px; }
.targetList {
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px; } }
.btn.active {
  border-color: #536f8b!important;
  border-width: 3px; }
.album {
  &__img {
    max-width: 100%; } }
.timeLine {
  &__img {
    max-width: 100%; } }
.loadButton {
  transform: translate(-50%, -7px);
  position: relative;
  left: 50%; }
.badge {
  color: #fff;
  font-size: 0.9em;
  &-primary {
    background-color: #A48ACB; }
  &-warning {
    background-color: #F6C04C; }
  &-danger {
    background-color: #FF9B90; }
  &-info {
    background-color: #66B5F3; } }

.customizeButtons {
  .btn {
    &-primary {
      background-color: #A48ACB;
      border-color: #A48ACB;
      &:not(:disabled):not(.disabled) {
        &:active, &.active {
          background-color: #A48ACB; } } }
    &-warning {
      background-color: #F6C04C;
      border-color: #F6C04C;
      &:not(:disabled):not(.disabled) {
        &:active, &.active {
          background-color: #F6C04C; } } }
    &-danger {
      background-color: #FF9B90;
      border-color: #FF9B90;
      &:not(:disabled):not(.disabled) {
        &:active, &.active {
          background-color: #FF9B90; } } }
    &-info {
      background-color: #66B5F3;
      border-color: #66B5F3;
      &:not(:disabled):not(.disabled) {
        &:active, &.active {
          background-color: #66B5F3; } } } } }
.avatar {
  &__block {
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50% !important;
    background-color: #f7f7ff;
    border: 1px solid #f0eff5;
    background-size: cover;
    background-position: center;
    &--xs {
      height: 2.2rem;
      width: 2.2rem; }
    &--lg {
      width: 6rem;
      height: 6rem; }
    &--xxl {
      width: 12rem;
      height: 12rem;
      line-height: 12rem;
      @include phone {
        width: 6rem;
        height: 6rem;
        line-height: 6rem; } } }
  &__img {
    max-width: 100%; } }
.chat-conversation {
  height: calc(100vh - 184px);
  @include phone {
    height: calc(100vh - 227px); }
  li {
    display: inline-block;
    width: 100%;
    &.left {
      text-align: left; }
    &.right {
      text-align: right; }
    &.system {
      text-align: center; } } }
.keywordClear {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #f5f7fb;
  input {
    padding-left: 2em; }
  button {
    position: absolute;
    right: 0;
    top: 0; } }
.css-jzgs6z.active {
  color: #000!important; }
.css-ubq01d.active {
  transform: scale(1)!important; }
.css-1ot189t.active {
  color: #000!important; }
